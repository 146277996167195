
const userConstants = {


	REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
	REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
	REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

	LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
	LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
	LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

	LOGOUT: 'USERS_LOGOUT',

	REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
	REFRESH_TOKEN_FAILURE: 'REFRESH_TOKEN_FAILURE',

	GET_USER_LIST: 'GET_USER_LIST',
	GET_USER_PROFILE: 'GET_USER_PROFILE',

	CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
	CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

	UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',

	UPDATE_USER_SUCCESS:'UPDATE_USER_SUCCESS',
	UPDATE_USER_FAILURE:'UPDATE_USER_FAILURE',

	GETALL_REQUEST: 'USERS_GETALL_REQUEST',
	GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
	GETALL_FAILURE: 'USERS_GETALL_FAILURE',

	DELETE_REQUEST: 'USERS_DELETE_REQUEST',
	DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
	DELETE_FAILURE: 'USERS_DELETE_FAILURE',

};

export default userConstants;
