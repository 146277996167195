/* eslint-disable no-useless-escape */
import * as Yup from 'yup';

import { useEffect, useState } from 'react';

import { FaTimes } from 'react-icons/fa';
import FormSignIn from './components/FormSignIn';
import FormSignUp from './components/FormSignUp';
import SloganLanding from './components/SloganLanding';
import ToastNotify from '../../utils/Toast';
import authActions from '../../redux/actions/auth.action';
import authService from '../../services/auth.service';
import { useDispatch } from 'react-redux';

import { useFormik } from 'formik';
import FormForgotPassword from './components/FormForgotPassword';
import userService from '../user/User.service';
import FormConfirm from './components/FormConfirm';

const USERNAME_EXSITED = 400;
const USERNAME_NO_EXSITED = 404;
const WRONG_PASSWORD = 401;

// LOGIN
const INIT_FORM_LOGIN = {
	username: '',
	password: '',
};
const SCHEMA_LOGIN = Yup.object({
	username: Yup.string()
		.trim()
		.required('Vui lòng không để trống tên đăng nhập!'),
	password: Yup.string()
		.trim()
		.required('Vui lòng không để trống mật khẩu!'),
});

// SIGNUP
const INIT_FORM_SIGNUP = {
	username: '',
	password: '',
	confirmPassword: '',
};
const SCHEMA_SIGNUP = Yup.object({
	username: Yup.string()
		.trim()
		.required('Vui lòng không để trống tên đăng nhập!')
		.matches(
			/^[a-zA-Z][a-zA-Z0-9]{5,19}$/,
			'Vui lòng nhập tên đăng nhập hợp lệ (bắt đầu bằng chữ cái và gồm 6-20 ký tự)'
		),
	password: Yup.string()
		.trim()
		.required('Vui lòng không để trống mật khẩu!')
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,20}$/,
			'Vui lòng nhập mật khẩu hợp lệ (gồm 6-20 ký tự chữ thường, chữ in hoa, và ký tự số)'
		),
	confirmPassword: Yup.string()
		.trim()
		.required('Vui lòng không để trống mật khẩu xác nhận!')
		.oneOf(
			[Yup.ref('password'), null],
			'Nhập lại mật khẩu không chính xác!'
		),
});

const emailRegex =
	/^[a-z][a-z0-9\._-]{1,90}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/;

const INIT_FORM_FORGOTPASSWORD = {
	email: '',
};
const SCHEMA_FORGOTPASSWORD = Yup.object({
	email: Yup.string()
		.trim()
		.required('Vui lòng không để trống email!')
		.matches(emailRegex, 'Email không hợp lệ!'),
});

function ModalLogin({ isOpen, funToggle, defaultMode }) {
	const dispatch = useDispatch();
	// const [isTabLogin, setIsTabLogin] = useState(true)
	const [mode, setMode] = useState(defaultMode || 'login');
	const [user, setUser] = useState();
	const [checkValid, setCheckValid] = useState(false);
	useEffect(() => {
		setCheckValid(false);
	}, [mode]);
	const formikLogin = useFormik({
		initialValues: INIT_FORM_LOGIN,
		validationSchema: SCHEMA_LOGIN,
		onSubmit: () => handleLogin(),
	});
	const formikSignUp = useFormik({
		initialValues: INIT_FORM_SIGNUP,
		validationSchema: SCHEMA_SIGNUP,
		onSubmit: () => handleSignup(),
	});
	const formikForgotPassword = useFormik({
		initialValues: INIT_FORM_FORGOTPASSWORD,
		validationSchema: SCHEMA_FORGOTPASSWORD,
		onSubmit: () => handleCheckEmail(),
	});

	const handleLogin = () => {
		dispatch(
			authActions.login(
				formikLogin.values.username,
				formikLogin.values.password
			)
		)
			.then(() => {
				window.location.reload();
			})
			.catch((error) => {
				switch (error.status) {
					case USERNAME_NO_EXSITED:
						formikLogin.setFieldError(
							'username',
							'Tên đăng nhập không tồn tại!'
						);
						break;
					case WRONG_PASSWORD:
						formikLogin.setFieldError(
							'password',
							'Sai mật khẩu!'
						);
						break;
					default:
						break;
				}
			});
	};

	const handleSignup = () => {
		authService
			.handleSignUp(
				formikSignUp.values.username,
				formikSignUp.values.password
			)
			.then(() => {
				notifySigin();
				changeMode();
				formikLogin.setFieldValue(
					'username',
					JSON.parse(localStorage.getItem('user'))
						.user_name,
					false
				);
				setMode('login');
			})
			.catch((error) => {
				switch (error.statusCode) {
					case USERNAME_EXSITED:
						formikSignUp.setFieldError(
							'username',
							'Tên đăng nhập đã tồn tại!'
						);
						break;
					default:
						break;
				}
			});
	};

	const handleSendEmailVerify = (email) => {
		userService
			.forgotPassword(email)
			.then(() => {
				ToastNotify.Success(
					'Hệ thống đã gửi email cho bạn!'
				);
			})
			.catch((err) => {
				ToastNotify.Error(err.message);
				formikForgotPassword.setFieldError(
					'email',
					err.message
				);
			});
	};
	const handleCheckEmail = () => {
		userService
			.checkEmail(formikForgotPassword.values.email)
			.then((data) => {
				setUser(data);
				setMode('confirm');
			})
			.catch((err) => {
				ToastNotify.Error(err.message);
				formikForgotPassword.setFieldError(
					'email',
					err.message
				);
			});
	};
	const toggleForm = () => {
		setCheckValid(false);
		funToggle();
		formikLogin.resetForm();
		formikSignUp.resetForm();
	};

	const changeMode = () => {
		formikLogin.resetForm();
		formikSignUp.resetForm();
		formikForgotPassword.resetForm();
	};

	const notifySigin = () => {
		ToastNotify.Success(
			'Đăng ký thành công, đăng nhập để tiếp tục'
		);
	};

	return (
		<>
			{isOpen && (
				<div
					className="fixed left-0 top-0 z-[100] h-[100vh] w-[100vw] overflow-y-hidden bg-cover bg-no-repeat outline-none focus:outline-none"
					style={{ margin: 0 }}
				>
					<div className="relative">
						<button
							className="absolute right-5 top-4 focus:bg-gray-300 focus:shadow-lg focus:outline-none "
							type="button"
							onClick={toggleForm}
						>
							<FaTimes className="text-3xl text-gray-400 hover:text-yellow-300 focus:bg-neutral-950" />
						</button>

						<div
							className="block w-full rounded-lg border-0 bg-lotus_blur bg-cover bg-center"
							// style={{ backgroundColor: '#f3f4f6' }}
						>
							<div className="mx-auto px-6 md:px-20 lg:px-40 xl:px-48">
								<div className="flex h-screen flex-col justify-evenly text-center md:flex-row md:items-center md:text-left">
									<SloganLanding />

									{mode ===
										'login' && (
										<FormSignIn
											setMode={
												setMode
											}
											formikLogin={
												formikLogin
											}
											changeMode={
												changeMode
											}
											checkValid={
												checkValid
											}
											setCheckValid={
												setCheckValid
											}
										/>
									)}
									{mode ===
										'register' && (
										<FormSignUp
											setMode={
												setMode
											}
											formikSignUp={
												formikSignUp
											}
											changeMode={
												changeMode
											}
											checkValid={
												checkValid
											}
											setCheckValid={
												setCheckValid
											}
										/>
									)}
									{mode ===
										'forgot-password' && (
										<FormForgotPassword
											setMode={
												setMode
											}
											formikForgotPassword={
												formikForgotPassword
											}
											changeMode={
												changeMode
											}
										/>
									)}
									{mode ===
										'confirm' && (
										<FormConfirm
											handleSendEmailVerify={
												handleSendEmailVerify
											}
											setMode={
												setMode
											}
											changeMode={
												changeMode
											}
											user={
												user
											}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
export default ModalLogin;
