import userConstants from '../../config/redux/user.redux';

const user = localStorage.getItem('user');
const initialState = user ? { loggedIn: true, user: JSON.parse(user) } : {};

function authReducer(state = initialState, action) {
	switch (action.type) {
	case userConstants.LOGIN_REQUEST:
		return {
			loggedIn: false,
			user: {},
		};
	case userConstants.LOGIN_SUCCESS:
		return {
			loggedIn: true,
			user: action.payload,
		};
	case userConstants.LOGIN_FAILURE:
		return {};

	default:
		return state;
	}
}

export default authReducer;
