import { useFormik } from 'formik';
import * as Yup from 'yup';

import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Loading from '../../components/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import userService from '../user/User.service';
import ToastNotify from '../../utils/Toast';
import { useDispatch } from 'react-redux';
import modalAction from '../../redux/modal/modal.action';
import ConfirmExitModal from './components/ConfirmExitModal';

const INIT_FORM_RESSETPASSWORD = {
	password: '',
	confirmPassword: '',
};
const SCHEMA_RESSETPASSWORD = Yup.object({
	password: Yup.string()
		.required('Mật khẩu mới không được bỏ trống!')
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,20}$/,
			'Mật khẩu gồm 6-20 ký tự chữ thường, chữ in hoa, và ký tự số'
		),
	confirmPassword: Yup.string()
		.required('Mật khẩu xác nhận không được bỏ trống!')
		.oneOf(
			[Yup.ref('password'), null],
			'Mật khẩu xác nhận phải giống mật khẩu mới!'
		),
});

export default function ResetPassword() {
	const [loading, setLoading] = useState(true);
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [user, setUser] = useState();

	useEffect(() => {
		userService
			.checkUser(id)
			.then((data) => {
				setUser(data);
			})
			.catch((err) => {
				ToastNotify.Error(err.message);
				navigate('/');
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);
	const formikResetPassword = useFormik({
		initialValues: INIT_FORM_RESSETPASSWORD,
		validationSchema: SCHEMA_RESSETPASSWORD,
		onSubmit: () => handleResetPassword(),
	});
	const handleResetPassword = () => {
		userService
			.resetPassword(id, formikResetPassword.values.password)
			.then(() => {
				ToastNotify.Success(
					'Cập nhập mật khẩu thành công!'
				);
				navigate('/');
				dispatch(modalAction.openModalLogin());
				// setMode('success')
			})
			.catch((err) => {
				ToastNotify.Error(err.message);
				navigate('/');
			});
	};
	const [showPassword, setShowPassWord] = useState({
		password: false,
		confirmPassword: false,
	});
	const [check, setCheck] = useState({
		password: false,
		confirmPassword: false,
	});
	if (loading) {
		return <Loading></Loading>;
	}
	const showUserDisplayName = (username) => {
		const showFirstChar = 0;
		const showSecondChar = 2;
		if (username) {
			return username
				.split('')
				.slice(showFirstChar, showSecondChar)
				.join('')
				.toUpperCase();
		}
		return 'User';
	};
	return (
		<>
			<div className="flex h-[100vh] w-[100vw] items-center justify-center bg-gray-200">
				<div className="w-full max-w-lg">
					<form
						className="mb-4 flex flex-col rounded-xl bg-white px-8 pb-8 pt-6 shadow-md"
						onSubmit={
							formikResetPassword.handleSubmit
						}
					>
						<div
							className={
								'relative mx-auto inline-flex h-20 w-20 items-center justify-center  overflow-hidden rounded-full bg-[#7265e6] text-center '
							}
						>
							<span className="text-3xl font-medium text-white ">
								{showUserDisplayName(
									user.user_name
								)}
							</span>
						</div>
						<p className="mx-auto mt-3 text-2xl font-bold text-black">
							{user.user_name}
						</p>
						<div
							id="input"
							className="my-2 flex w-full flex-col"
						>
							<label
								htmlFor="password"
								className="mb-2 text-left text-gray-500"
							>
								Mật khẩu mới
							</label>
							<div className="relative flex items-center">
								<input
									type={
										showPassword.password
											? 'text'
											: 'password'
									}
									id="password"
									name="password"
									placeholder="Nhập mật khẩu mới"
									className={`w-full rounded-lg border px-4 py-3 text-black placeholder:text-gray-300 focus:shadow-lg focus:outline-none focus:ring-1 ${
										formikResetPassword
											.errors
											.password &&
										check.password &&
										'border-red-500'
									}`}
									onBlur={() => {
										setCheck(
											{
												...check,
												password: true,
											}
										);
									}}
									value={
										formikResetPassword
											.values
											.password
									}
									onChange={(
										event
									) =>
										formikResetPassword.setFieldValue(
											'password',
											event.target.value.trim()
										)
									}
								/>
								{showPassword.password ? (
									<FaEyeSlash
										className="absolute right-3 cursor-pointer"
										onClick={() => {
											setShowPassWord(
												{
													...showPassword,
													password: false,
												}
											);
										}}
									/>
								) : (
									<FaEye
										className="absolute right-3 cursor-pointer"
										onClick={() => {
											setShowPassWord(
												{
													...showPassword,
													password: true,
												}
											);
										}}
									/>
								)}
							</div>
							{check.password &&
								formikResetPassword
									.errors
									.password && (
									<div className="mb-3">
										<span className="mt-1 text-left text-sm text-red-500">
											{
												formikResetPassword
													.errors
													.password
											}
										</span>
									</div>
								)}
						</div>
						<div
							id="input"
							className="my-2 flex w-full flex-col"
						>
							<label
								htmlFor="confirmPassword"
								className="mb-2 text-left text-gray-500"
							>
								Xác nhận lại mật
								khẩu
							</label>
							<div className="relative flex items-center">
								<input
									type={
										showPassword.confirmPassword
											? 'text'
											: 'password'
									}
									id="confirmPassword"
									name="confirmPassword"
									placeholder="Nhập mật khẩu xác nhận"
									className={`w-full rounded-lg border px-4 py-3 text-black placeholder:text-gray-300 focus:shadow-lg focus:outline-none focus:ring-1 ${
										formikResetPassword
											.errors
											.confirmPassword &&
										check.confirmPassword &&
										'border-red-500'
									}`}
									onBlur={() => {
										setCheck(
											{
												...check,
												confirmPassword: true,
											}
										);
									}}
									value={
										formikResetPassword
											.values
											.confirmPassword
									}
									onChange={(
										event
									) =>
										formikResetPassword.setFieldValue(
											'confirmPassword',
											event
												.target
												.value
										)
									}
								/>
								{showPassword.confirmPassword ? (
									<FaEyeSlash
										className="absolute right-3 cursor-pointer"
										onClick={() => {
											setShowPassWord(
												{
													...showPassword,
													confirmPassword: false,
												}
											);
										}}
									/>
								) : (
									<FaEye
										className="absolute right-3 cursor-pointer"
										onClick={() => {
											setShowPassWord(
												{
													...showPassword,
													confirmPassword: true,
												}
											);
										}}
									/>
								)}
							</div>
							{check.confirmPassword &&
								formikResetPassword
									.errors
									.confirmPassword && (
									<div className="mb-3">
										<span className="mt-1 text-left text-sm text-red-500">
											{
												formikResetPassword
													.errors
													.confirmPassword
											}
										</span>
									</div>
								)}
						</div>
						<div className="my-5 flex w-full flex-col">
							<button
								type="submit"
								className="w-full rounded-lg bg-gradient-to-b from-[#51ADF5] to-[#2196F3] py-4 text-white hover:from-[#2196F3] hover:to-[#51ADF5]  hover:shadow-lg "
							>
								<div className="flex flex-row items-center justify-center">
									<div className="font-bold capitalize text-white">
										Xác
										nhận
									</div>
								</div>
							</button>
							<button
								type="button"
								className="mt-3 w-full rounded-lg bg-gray-300 py-4 text-white hover:shadow-lg "
								// onClick={() => {
								//     navigate('/')
								// }}
								data-bs-toggle="modal"
								data-bs-target="#confirmExitModal"
							>
								<div className="flex flex-row items-center justify-center">
									<div className="font-bold capitalize text-gray-700">
										Hủy
									</div>
								</div>
							</button>
						</div>
					</form>
				</div>
			</div>
			<ConfirmExitModal navigate={navigate} />
		</>
	);
}
