/* eslint-disable tailwindcss/no-custom-classname */
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '../../../Avatar';
import NavbarRoute from '../../../../routes/Navbar.route';
import TippyMenu from '../../../TippyMenu/TippyMenu';
import modalAction from '../../../../redux/modal/modal.action';
import Popover from '../../../Popover/Popover';
import LogOutModal from '../../../../views/auth/ModalLogout';

const NavbarDefault = () => {
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);
	const [loggedIn, setLoggedIn] = useState(false);
	const [isOpenTippyMenu, setIsOpenTippyMenu] = useState(false);

	// const [isActiveLoginTab, setIsActiveLoginTab] = useState(true)
	// const [isOpenModalLogin, setIsOpenModalLogin] = useState(false)

	//scroll
	const [colorNav, setcolorNav] = useState(false);

	// popper
	const [isOpenPopper, setIsOpenPopper] = useState(false);

	const showLoginForm = (event) => {
		event.preventDefault();
		dispatch(modalAction.openModalLogin());
		// setIsOpenModalLogin(true)
		// setIsActiveLoginTab(true)
	};

	const showRegisterForm = (event) => {
		event.preventDefault();
		dispatch(modalAction.openModalSignUp());
	};

	// const toggleModalLogin = () => {
	//     setIsOpenModalLogin(!isOpenModalLogin)
	// }

	const onOpenTippyMenu = () => {
		setIsOpenTippyMenu(true);
	};

	const onCloseTippyMenu = () => {
		setIsOpenTippyMenu(false);
	};

	useEffect(() => {
		if (auth) {
			setLoggedIn(auth.loggedIn);
		}
	}, [auth]);

	useEffect(() => {
		setIsOpenTippyMenu(false);
		if (pathname !== '/') {
			setcolorNav(true);
		} else {
			setcolorNav(false);
		}
	}, [pathname]);

	useEffect(() => {
		window.addEventListener('scroll', () => {
			const header =
				document.querySelector('header').offsetHeight;
			if (window.scrollY >= header || pathname !== '/') {
				setcolorNav(true);
			} else {
				setcolorNav(false);
			}
		});
	}, [pathname]);

	return (
		<header className="fixed inset-x-0 top-0 z-[99]">
			<LogOutModal />
			<div id="headlessui-portal-root">
				<TippyMenu
					loggedIn={loggedIn}
					user={auth?.user}
					visible={isOpenTippyMenu}
					onClose={onCloseTippyMenu}
					showLoginForm={showLoginForm}
					showRegisterForm={showRegisterForm}
				/>
			</div>

			<div
				className={`sticky top-0 z-[90] w-full flex-none ${
					!colorNav ? '' : 'bg-[#131314]'
				} transition-colors  duration-500 lg:border-b lg:border-slate-900/10`}
			>
				<div className="mx-auto max-w-[90rem]">
					<div className="mx-4 border-b border-slate-900/10 py-4 lg:mx-0 lg:border-0 lg:px-8">
						<div className="relative flex items-center">
							<a
								href="/"
								className="mr-3 w-[4.8rem] flex-none md:w-auto"
							>
								<span className="sr-only">
									HomePage
								</span>
								<h1 className="text-2xl font-extrabold text-amber-500">
									ChiaBuonDienTu
								</h1>
							</a>
							<div className="my-1 ml-auto lg:hidden">
								<button
									type="button"
									className="flex h-8 w-8 items-center justify-center text-yellow-500 hover:text-white"
									onClick={
										onOpenTippyMenu
									}
								>
									<span className="sr-only">
										Menu
									</span>
									<svg
										width="24"
										height="24"
										fill="none"
										aria-hidden="true"
										className="text-xl"
									>
										<path
											d="M12 6v.01M12 12v.01M12 18v.01M12 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										></path>
									</svg>
								</button>
							</div>
							<div className="relative ml-auto hidden items-center lg:flex">
								<nav className="text-sm font-semibold leading-6 text-slate-700 ">
									<ul className="flex space-x-8">
										{NavbarRoute.map(
											(
												navbar,
												index
											) => {
												if (
													navbar.element
												) {
													const isActive =
														navbar.path ===
														pathname;
													return (
														<li
															key={
																index
															}
														>
															<a
																href={
																	navbar.path
																}
																className={`hover:text-amber-500  ${
																	isActive
																		? 'text-amber-500'
																		: 'text-amber-50'
																}`}
															>
																{
																	navbar.content
																}
															</a>
														</li>
													);
												}
												return (
													<li
														key={
															index
														}
													>
														<a
															href={
																navbar.path
															}
															className="text-amber-50 hover:text-amber-500"
														>
															{
																navbar.content
															}
														</a>
													</li>
												);
											}
										)}
										{!loggedIn && (
											<>
												<li>
													<Link
														className="text-amber-50 hover:text-amber-500"
														to="#"
														onClick={
															showLoginForm
														}
													>
														Đăng
														nhập
													</Link>
												</li>
												<li>
													<button
														className="flex h-8 w-full items-center justify-center rounded-lg bg-amber-400 px-3 font-semibold text-black focus:outline-none focus:ring-2 sm:w-auto"
														onClick={
															showRegisterForm
														}
													>
														Đăng
														ký
													</button>
												</li>
											</>
										)}
									</ul>
								</nav>
								{loggedIn && (
									<>
										<div className="ml-6 flex items-center border-l border-slate-200 pl-6 ">
											<div className="relative ml-3 cursor-pointer">
												<div
													// onClick={funTogglePopper}
													onMouseEnter={() =>
														setIsOpenPopper(
															true
														)
													}
													onMouseLeave={() =>
														setIsOpenPopper(
															false
														)
													}
												>
													<span className="sr-only">
														Avatar
														User
													</span>
													<Avatar
														username={
															auth
																.user
																.user_name
														}
														avatarUrl={
															auth
																.user
																.avatar
														}
													/>
												</div>
												{isOpenPopper && (
													<Popover
														username={
															auth
																.user
																.user_name
														}
														setIsOpenPopper={
															setIsOpenPopper
														}
													/>
												)}
											</div>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default NavbarDefault;
