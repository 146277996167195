const Avatar = ({ username, avatarUrl }) => {
	const showUserDisplayName = () => {
		const showFirstChar = 0;
		const showSecondChar = 2;
		if (username) {
			return username
				.split('')
				.slice(showFirstChar, showSecondChar)
				.join('')
				.toUpperCase();
		}
		return 'User';
	};

	if (!avatarUrl) {
		return (
			<div
				className={`inline-flex
					overflow-hidden 
					relative 
					justify-center
					items-center w-8 h-8 bg-yellow-400 rounded-full `}
			>
				<span className="text-xs font-medium text-black ">
					{showUserDisplayName()}
				</span>
			</div>
		);
	}
	return (
		<img
			className="h-8 w-8 rounded-full"
			src={avatarUrl}
			alt="Avatar User"
		/>
	);
};

export default Avatar;
