import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import PAGE from '../config/path.route';
import modalAction from '../redux/modal/modal.action';
import { useDispatch } from 'react-redux';
import userService from '../views/user/User.service';

const ProtectedRoute = ({ children }) => {
	const location = useLocation();
	const [, setSearchParams] = useSearchParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isLogin, setIsLogin] = useState(false);
	const timeOut = 20;

	useEffect(() => {
		userService
			.whoAmI()
			.then(() => {
				console.log('1');
				setIsLogin(true);
			})
			.catch((error) => {
				setIsLogin(false);
				if (
					error.message ===
					'NOT_FOUND_REFRESH_TOKEN'
				) {
					setTimeout(() => {
						dispatch(
							modalAction.openModalLogin()
						);
					}, timeOut);
				} else {
					navigate(PAGE.dashboard);
				}
			});
		return () => setIsLogin(false);
	}, [dispatch, location, setSearchParams, navigate]);

	return isLogin && children;
};

export default ProtectedRoute;
