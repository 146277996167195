import React from 'react';
import ModalManagement from '../../ModalManagement/ModalManagement';
const LayoutEmpty = ({ children }) => (
	<div>
		<ModalManagement />
		{children}
	</div>
);

export default LayoutEmpty;
