export const ACCESS_TOKEN_KEY = 'token';
export const REFRESH_TOKEN_KEY = 'rtoken';
export const EMPTY = 0;
export const UNAUTHORIZED_KEY = 401;
export const SUCCESS_KEY = 200;

export const PRODUCT_LIST = [
	{
		name: 'Gia phả đại việt',
		image: './giaphadaiviet.jpg',
		link: 'https://giaphaso.com/',
		des: `Với hơn 10 năm nghiên cứu văn hóa và phát triển 
		công nghệ xây dựng gia phả thuận tiện thông minh, 
        Gia Phả Đại Việt vinh dự cung cấp dịch vụ về xây dựng gia phả 
		cho các dòng họ tại Việt Nam.`,
	},
	{
		name: 'Phần mềm Gia Phả',
		image: './logo-gia-pha.jpg',
		des: `Chúng tôi tự hào cung cấp một phương thức thân thiện để giúp các gia đình làm
        và quản lý gia phả bằng các ứng dụng phần mềm công nghệ thông minh.`,
		link: 'https://giapha.akb.com.vn/',
	},
	{
		name: 'Gia phả số',
		image: './giaphaso.png',
		des: `Gia phả Số Đại Việt trực tuyến là giải pháp trong lĩnh vực văn hóa Việt Nam,
		 áp dụng công nghệ phát triển website gia phả cho các dòng họ.`,
		link: 'https://giaphaso.com/',
	},
	{
		name: 'Chung Vui',
		image: './logo_chungvui.png',
		des: `Các loại thiệp bằng giấy dần đã được thay thế loại thiệp điện tử. 
        Không những giúp bạn tiết kiệm về thời gian, chi phí, mà còn tiện lợi, hiện đại.`,
		link: 'https://chungvui.net/',
	},
	{
		name: 'Nhân số đại việt',
		image: './nhansodaiviet.webp',
		des: `Mục tiêu số 1 của công cụ tra cứu nhân số học Nhân Số Đại Việt là hỗ trợ bạn 
                trong hành trình nhận thức sâu sắc về bản ngã và trở nên tự tin hơn.`,
		link: 'https://nhansodaiviet.com/',
	},
];
