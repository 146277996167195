import { Link } from 'react-router-dom';
import PAGE from '../../../../config/path.route';
import React from 'react';
import {PRODUCT_LIST} from '../../../../config/constant/common.constant';

const FooterDefault = () => (
	<footer className="mt-16 text-sm leading-6">
		<div className="flex flex-col justify-center gap-x-10 gap-y-5 border-t border-slate-200 px-5 pb-28 pt-10 text-slate-300 sm:flex sm:flex-row sm:px-20 ">
			<div className="flex max-w-400-px flex-col">
				<h1 className="mb-3 text-2xl font-bold text-yellow-400">Công Ty</h1>
				<p className="mb-3">Công Ty Cổ Phần Phát Triển Giải Pháp Số Đại Việt</p>
				<p className="mb-3">Giám đốc: Trần Thu Thuỷ</p>
				<p className="mb-3">Mã số thuế: 0109885713</p>
				<img src="/congty.jpg" className="h-auto w-1/2 object-cover" alt="logo" />
			</div>
			<div className="flex max-w-400-px flex-col">
				<h1 className="mb-3 text-2xl font-bold text-yellow-400 ">Liên hệ</h1>
				<p className="mb-3"> (+84) 979.33.88.11</p>
				<p className="mb-3">info@daviso.vn</p>
				<p className="mb-3">
					<a
						href="https://daviso.vn/"
						className="underline  hover:text-yellow-400"
						target="_blank"
						rel="noreferrer"
					>
                            www.daviso.vn
					</a>
				</p>
				<p className="mb-3">Số 23, ngõ 412 đường Ngọc Thụy,</p>
				<p className="mb-3">Quận Long Biên, Hà Nội</p>
			</div>
			<div className="flex ">
				<div className="flex flex-col">
					<h1 className="mb-3 text-2xl font-bold text-yellow-400">Sản phẩm khác</h1>
					{PRODUCT_LIST.map((product, index) => (
						<div key={index} className="flex w-auto flex-row py-2">
							<img
								alt="anh-san-pham"
								src={
									product.image
								}
								className="w-[30px] rounded-md"
							/>
							<a
								href={
									product.link
								}
								target="_blank"
								rel="noopener noreferrer"
								className="underline hover:text-yellow-400 ml-2"
							>
									{product.name}
							</a>
						</div>
					))}
				</div>
			</div>
			<div className="flex ">
				<div className="flex flex-col">
					<h1 className="mb-3 text-2xl font-bold text-yellow-400">Giới thiệu</h1>

					<Link className="mb-3 underline hover:text-yellow-400 " to={PAGE.policy}>
                            Chính sách bảo mật
					</Link>
					<Link
                            className="mb-3 underline hover:text-yellow-400 "
                            to={PAGE.aboutus}
                        >
                            Về chúng tôi
					</Link>
				</div>
			</div>
		</div>
	</footer>
);

export default FooterDefault;
