import PAGE from '../../config/path.route';

const Popover = ({ username, setIsOpenPopper }) => {
	const setPopper = (val) => {
		setIsOpenPopper(val);
	};
	const showUserDisplayName = () => {
		const showFirstChar = 0;
		const showSecondChar = 2;
		if (username) {
			return username
				.split('')
				.slice(showFirstChar, showSecondChar)
				.join('')
				.toUpperCase();
		}
		return 'User';
	};
	return (
		<div
			className="z-[9999] rounded-lg bg-blueGray-950 shadow-lg"
			style={{
				position: 'absolute',
				inset: '10px 0px auto auto',
				margin: '0px',
				transform: 'translate(0px, 21px)',
			}}
			onMouseEnter={() => setPopper(true)}
			onMouseLeave={() => setPopper(false)}
		>
			<ul
				className="rounded-lg bg-blueGray-950 py-4 pl-6 pr-4 "
				style={{
					borderRadius: '10px',
					margin: '0',
					overflow: 'hidden',
					transition: 'all .3s ease-in-out',
					willChange: 'opacity,transform',
				}}
			>
				<div className="mb-4 flex pr-6">
					<div
						className={
							'relative inline-flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-yellow-400 '
						}
					>
						<span className="text-sm font-medium text-black ">
							{showUserDisplayName()}
						</span>
					</div>
					<p className="ml-3 mt-2 text-lg font-bold text-yellow-400">
						{username}
					</p>
				</div>
				<hr />
				<p
					className="my-3 cursor-pointer text-sm text-white hover:text-yellow-400"
					// onClick={funToggle}
				>
					<a href={PAGE.profile}>Trang cá nhân</a>
				</p>
				<hr />
				<p
					className="my-3 cursor-pointer text-sm text-white hover:text-yellow-400"
					data-bs-toggle="modal"
					data-bs-target="#logOutModal"
				>
					Đăng xuất
				</p>
			</ul>
		</div>
	);
};

export default Popover;
