import axios from './axios';

export const getAllUser = () => axios.get('/user/getAll');
export const editUser = (id, user) =>
	axios.patch(`/user/update-info/${id}`, user);
export const analysisUser = () => axios.get('/user/analysis');
export const whoAmI = () => axios.get('/user/who-am-i');
export const forgotPassword = (payload) =>
	axios.post('/user/forgot-password', payload);
export const checkEmail = (query) =>
	axios.get('/user/forgot-password', { params: query });
export const checkUser = (params) => axios.get(`user/reset-password/${params}`);
export const resetPassword = (params, payload) =>
	axios.patch(`user/reset-password/${params}`, payload);
