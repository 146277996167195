import modalTypes from './modal.type';

const INIT = {
	modalLogin: {
		isOpen: false,
	},
	modalSignUp: {
		isOpen: false,
	},
	modalLogout: {
		isOpen: false,
	},
	modalDelete: {
		isOpen: false,
	},
};
function modalReducer(state = INIT, action) {
	const { type } = action;
	switch (type) {
	case modalTypes.OPEN_MODAL_LOGIN:
		return {
			...state,
			modalLogin: {
				...state.modalLogin,
				isOpen: true,
			},
		};
	case modalTypes.CLOSE_MODAL_LOGIN:
		return {
			...state,
			modalLogin: {
				...state.modalLogin,
				isOpen: false,
			},
		};
	case modalTypes.OPEN_MODAL_SIGNUP:
		return {
			...state,
			modalSignUp: {
				...state.modalLogin,
				isOpen: true,
			},
		};
	case modalTypes.CLOSE_MODAL_SIGNUP:
		return {
			...state,
			modalSignUp: {
				...state.modalLogin,
				isOpen: false,
			},
		};

	case modalTypes.OPEN_MODAL_LOGOUT:
		return {
			...state,
			modalLogout: {
				...state.modalLogout,
				isOpen: true,
			},
		};
	case modalTypes.CLOSE_MODAL_LOGOUT:
		return {
			...state,
			modalLogout: {
				...state.modalLogout,
				isOpen: false,
			},
		};

	case modalTypes.OPEN_MODAL_DELETE:
		return {
			...state,
			modalDelete: {
				...state.modalDelete,
				isOpen: true,
			},
		};
	case modalTypes.CLOSE_MODAL_DELETE:
		return {
			...state,
			modalDelete: {
				...state.modalDelete,
				isOpen: false,
			},
		};
	default:
		return state;
	}
}

export default modalReducer;
