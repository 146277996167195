import { Link } from 'react-router-dom';
import PAGE from '../config/path.route';

const NavbarRoute = [
	{
		path: PAGE.dashboard,
		isProtect: true,
		content: 'Trang chủ',
		element: Link,
	},
	{
		path: PAGE.userEvents,
		isProtect: true,
		content: 'Cáo Phó',
		element: Link,
	},
];
export default NavbarRoute;
