import modalTypes from './modal.type';

const modalAction = {
	openModalLogin: () => ({
		type: modalTypes.OPEN_MODAL_LOGIN,
	}),
	closeModalLogin: () => ({
		type: modalTypes.CLOSE_MODAL_LOGIN,
	}),
	openModalSignUp: () => ({
		type: modalTypes.OPEN_MODAL_SIGNUP,
	}),
	closeModalSignUp: () => ({
		type: modalTypes.CLOSE_MODAL_SIGNUP,
	}),
	openModalLogout: () => ({
		type: modalTypes.OPEN_MODAL_LOGOUT,
	}),
	closeModalLogout: () => ({
		type: modalTypes.CLOSE_MODAL_LOGOUT,
	}),
	openModalDelete: () => ({
		type: modalTypes.OPEN_MODAL_DELETE,
	}),
	closeModalDelete: () => ({
		type: modalTypes.CLOSE_MODAL_DELETE,
	}),
};

export default modalAction;
