import authService from '../../services/auth.service';
import userConstants from '../../config/redux/user.redux';

const authActions = {
	login: (username, password) => {
		const loginRequest = (user) => ({
			type: userConstants.LOGIN_REQUEST,
			payload: user,
		});
		const loginSuccess = (user) => ({
			type: userConstants.LOGIN_SUCCESS,
			payload: user,
		});
		const loginFailure = (error) => ({
			type: userConstants.LOGIN_FAILURE,
			payload: error,
		});
		return async (dispatch) => {
			dispatch(loginRequest({ username }));
			return authService
				.handleLoginIn(username, password)
				.then((user) => {
					dispatch(loginSuccess(user));
					return Promise.resolve();
				})
				.catch((error) => {
					dispatch(loginFailure(error.errorMessage));
					return Promise.reject(error);
				});
		};
	},
	changePassword: (oldPassword, newPassword) => async () => authService
		.handleChangePassword(oldPassword, newPassword)
		.then(() => Promise.resolve())
		.catch((error) => Promise.reject(error)),
};

export default authActions;
