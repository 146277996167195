
import { useRef, createContext } from 'react';

const FocusContext = createContext();

const FocusProvider = ({ children }) => {

	// use Ref focus for form groom and bride
	const formGroom = useRef();
	const focusFormGroom = () => {
		formGroom.current.focus();
	};
	//
	const formOther = useRef();
	const focusFormOther = () => {
		formOther.current.focus();
	};
	const formBank = useRef();
	const focusFormBank = () => {
		formBank.current.focus();
	};
	const formParty = useRef();
	const focusFormParty = () => {
		formParty.current.focus();
	};
	const formSlider = useRef();
	const focusFormSlider = () => {
		formSlider.current.focus();
	};
	const formGeneral = useRef();
	const focusFormGeneral = () => {
		formGeneral.current.focus();
	};


	const val = {
		formGroom, focusFormGroom,
		formOther, focusFormOther,
		formBank, focusFormBank,
		formParty, focusFormParty,
		formSlider, focusFormSlider,
		formGeneral, focusFormGeneral,
	};

	return (
		<FocusContext.Provider value={val} >
			{children}
		</FocusContext.Provider>
	);

};

export { FocusContext, FocusProvider };