import '@fortawesome/fontawesome-free/css/all.min.css';
import '../index.css';
import '../slick.css';
import '../assets/styles/style.css';
import '../assets/styles/gg-fonts.css';

import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import LayoutDefault from '../components/Layout/LayoutDefault/LayoutDefault';
import Loading from '../components/Loading';
import MainRoute from './Main.route';

import ProtectedRoute from './Protected.route';
import LogOutModal from '../views/user/components/LogOutModal';

const finalRoutes = [...MainRoute];

export default function RouteApp() {
	return (
		<>
			<LogOutModal />
			<Routes>
				{finalRoutes.map((route, idx) => {
					const Layout =
						route.layout ?? LayoutDefault;
					const Element = route.element;
					const isProtect = route.isProtect;
					return (
						<Route
							key={idx}
							path={route.path}
							element={
								<Layout>
									<Suspense
										fallback={
											<Loading />
										}
									>
										{isProtect ? (
											<ProtectedRoute>
												<Element />
											</ProtectedRoute>
										) : (
											<Element />
										)}
									</Suspense>
								</Layout>
							}
						/>
					);
				})}
			</Routes>
		</>
	);
}
