import FooterDefault from './components/FooterDefault';
import ModalManagement from '../../ModalManagement/ModalManagement';
import NavbarDefault from './components/NavbarDefault';
import React from 'react';
import ScrollToTop from '../../ScrollToTop';

const LayoutDefault = ({ children }) => (
	// eslint-disable-next-line tailwindcss/no-custom-classname
	<div className="app__warpper bg-[#131314]">
		<div className="pointer-events-none absolute inset-x-0 top-0 z-[99] flex justify-center overflow-hidden">
			<div className="flex justify-end"></div>
		</div>
		<NavbarDefault />
		<ScrollToTop />
		<div>
			<div>
				{children}
				<ModalManagement />
				<FooterDefault />
			</div>
		</div>
	</div>
);

export default LayoutDefault;
