const PAGE = {
	dashboard: '/',
	aboutus: '/ve-chung-toi',
	policy: '/chinh-sach',
	createEvent: '/tao-cao-pho',
	templates: '/loai-thiep/:alias',
	templateView: '/xem-thiep/:alias',
	userEvents: '/cao-pho',
	publish: '/:urlShare',
	managerEventGeneral: '/su-kien/:eventId',
	error404: '/trang-khong-ton-tai-404',
	profile: '/tai-khoan',
	publishThumbnail: '/thumbnail/:urlShare',
	resetPassword: 'reset-password/:id',
};

export default PAGE;
