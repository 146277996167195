import 'react-toastify/dist/ReactToastify.css';

import { toast } from 'react-toastify';

const ToastNotify = {
    Success: (mess) => {
        toast.success(mess);
    },
    Error: (mess) => {
        toast.error(mess);
    },
    Info: (mess) => {
        toast.info(mess);
    },
    Warning: (mess) => toast.warning(mess),
    Loading: (mess = 'Đang lưu...') => toast.loading(mess),
    Promise: (fn, msgPending = 'Đang lưu...', msgSuccess = 'Thành công') =>
        toast.promise(fn, {
            pending: msgPending,
            success: msgSuccess,
            error: {
                render({ data }) {
                    return data.message;
                },
            },
        })

};

export default ToastNotify;
