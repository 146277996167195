/* eslint-disable max-len */
import LayoutEmpty from '../components/Layout/LayoutEmpty';

import PAGE from '../config/path.route';
import { lazy } from 'react';

import ResetPassword from '../views/ResetPassword/ResetPassword';

const Dashboard = lazy(() => import('../views/dashboard/Dashboard.page'));
const AbouUs = lazy(() => import('../views/aboutus/Aboutus.page'));
const Policy = lazy(() => import('../views/policy/Policy.page'));
const UserInfo = lazy(() => import('../views/user/User.page'));
const PageNotFound = lazy(() => import('../views/404/404.page'));
const Events = lazy(() => import('../views/events/Events.page'));
const UserEvents = lazy(() => import('../views/user.events/User.events.page'));

const Publish = lazy(() => import('../views/publish/Publish.page'));

const MainRoute = [
	{
		path: PAGE.dashboard,
		// layout: LayoutLanding,
		element: Dashboard,
		isProtect: false,
		meta: {
			title: 'Trang chủ - Chia Buồn Điện Tử',
			description:
				'Chia buồn điện tử là một sản phẩm độc đáo và ý nghĩa, mang đến giải pháp hiện đại trong việc chia sẻ tình cảm khi người thân hoặc bạn bè đối diện với những thời điểm khó khăn. Với sự tiện lợi của công nghệ, sản phẩm này giúp kết nối những tâm hồn xa cách, tạo cơ hội để chia sẻ thông điệp ẩn sau những dòng lời chia buồn chân thành nhất. Sự độc đáo của chia buồn điện tử không chỉ nằm ở việc truyền đạt tình cảm mà còn ở khả năng tạo nên không gian tương tác trực tuyến, làm cho việc chia sẻ đau buồn trở nên gần gũi hơn bao giờ hết.',
			image: '/trang-chu.png',
		},
	},
	{
		path: PAGE.aboutus,
		element: AbouUs,
		isProtect: false,
		meta: {
			title: 'Về chúng tôi - Chia Buồn Điện Tử',
			description:
				'Chia buồn điện tử là một sản phẩm độc đáo và ý nghĩa, mang đến giải pháp hiện đại trong việc chia sẻ tình cảm khi người thân hoặc bạn bè đối diện với những thời điểm khó khăn. Với sự tiện lợi của công nghệ, sản phẩm này giúp kết nối những tâm hồn xa cách, tạo cơ hội để chia sẻ thông điệp ẩn sau những dòng lời chia buồn chân thành nhất. Sự độc đáo của chia buồn điện tử không chỉ nằm ở việc truyền đạt tình cảm mà còn ở khả năng tạo nên không gian tương tác trực tuyến, làm cho việc chia sẻ đau buồn trở nên gần gũi hơn bao giờ hết.',
			image: '/ve-chung-toi.png',
		},
	},
	{
		path: PAGE.policy,
		element: Policy,
		isProtect: false,
		meta: {
			title: 'Chính sách - Chia Buồn Điện Tử',
			description:
				'Khi sử dụng dịch vụ của ChungVui, bạn tin tưởng cung cấp thông tin của bạn cho ChungVui. ChungVui hiểu rằng đây là một trách nhiệm lớn và ChungVui nỗ lực bảo vệ thông tin của bạn cũng như để bạn nắm quyền kiểm soát. ChungVui chỉ sử dụng thông tin thu thập để: Cung cấp dịch vụ mà bạn yêu cầu; Hiểu cách bạn sử dụng Dịch vụ để ChungVui có thể cải thiện trải nghiệm của bạn; Cung cấp nội dung và quảng cáo theo đối tượng khách hàng.',
			image: '/ve-chung-toi.png',
		},
	},
	{
		path: PAGE.profile,
		element: UserInfo,
		isProtect: true,
		meta: {
			title: 'Tài khoản - Chia Buồn Điện Tử',
			description:
				'Xem tài khoản và chỉnh sửa thông tin tài khoản',
			image: '/tai-khoan.png',
		},
	},

	{
		path: PAGE.userEvents,
		element: UserEvents,
		isProtect: true,
		meta: {
			title: 'Cáo Phó - Chia Buồn Điện Tử',
			description:
				'Xem danh sách thiệp, chỉnh sửa và chia sẻ các mẫu thiệp',
			image: '/thiep-cua-toi.png',
		},
	},

	{
		path: PAGE.createEvent,
		element: Events,
		isProtect: true,
		layout: LayoutEmpty,
		meta: {
			title: 'Tạo thiệp - Chia Buồn Điện Tử',
			description: 'Tạo thiệp',
			image: '/tao-thiep.png',
		},
	},

	{
		path: PAGE.publish,
		element: Publish,
		isProtect: false,
		layout: 'a',
	},

	{
		path: '/*',
		element: PageNotFound,
		isProtect: false,
	},

	{
		path: PAGE.resetPassword,
		element: ResetPassword,
		layout: LayoutEmpty,
	},
];

export default MainRoute;
