import React from 'react';
import IMAGES from '../../../assets/images/_index';

const SloganLanding = () => (
	<div className="flex w-full flex-col ">
		<div>
			<img
				className="h-24 w-32 rounded-full mb-6"
				src={IMAGES.LOTUS}
				alt="profilepicture"
			/>
		</div>
		<h1 className="text-4xl font-bold text-yellow-500 sm:mb-2 sm:text-5xl">Chia Buồn Điện Tử</h1>
		<p className="mx-auto w-full text-gray-100 md:mx-0 md:w-5/12">Chia sẻ - Thông cáo - An ủi từ xa</p>
		<p className="mx-auto w-full text-gray-100 md:mx-0 md:w-5/12">Không gian kết nối tâm huyết và gắn kết trái tim.</p>
	</div>
);

export default SloganLanding;
