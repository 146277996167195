import {
	ACCESS_TOKEN_KEY,
	REFRESH_TOKEN_KEY,
} from '../../config/constant/common.constant';
import {
	analysisUser,
	editUser,
	getAllUser,
	whoAmI,
	forgotPassword,
	checkUser,
	resetPassword,
	checkEmail,
} from '../../api/user.api';

import Cookies from 'js-cookie';
import axios from 'axios';
import envConstants from '../../config/key';
import storageConstant from '../../config/constant/storage.constant';

const userService = {
	getAllUsers: async () => {
		const { data } = await getAllUser();
		return data;
	},
	editUserInfo: async (id, user) => {
		const { data } = await editUser(id, user);
		if (data.code === '200') {
			localStorage.setItem(
				storageConstant.USER,
				JSON.stringify(data.data)
			);
			return data;
		} else {
			const error = new Error();
			error.statusCode = data.code;
			error.errorMessage = data.message;
			error.data = data.data;
			return Promise.reject(error);
		}
	},
	analysis: async () => {
		const { data } = await analysisUser();
		return data;
	},
	whoAmI: async () => {
		try {
			const { data } = await whoAmI();
			const { data: user } = data;
			localStorage.setItem(
				storageConstant.USER,
				JSON.stringify(user)
			);
			return user;
		} catch (error) {
			return Promise.reject(error);
		}
	},

	getNewAccessAndRefreshToken: async () => {
		const refreshToken = Cookies.get(REFRESH_TOKEN_KEY);

		if (!refreshToken) {
			localStorage.removeItem(storageConstant.USER);
			return Promise.reject(
				new Error('NOT_FOUND_REFRESH_TOKEN')
			);
		}

		return axios
			.post(envConstants.API_URL + '/user/refresh-token', {
				refreshToken,
			})
			.then(({ data }) => {
				const { accessToken, refreshToken } = data.data;
				const date = new Date();
				let msUnit = 1000;
				let hourNumber = 3600;
				// AccessToken expr after 1 hour
				date.setTime(
					date.getTime() + hourNumber * msUnit
				);
				Cookies.set(ACCESS_TOKEN_KEY, accessToken, {
					expires: date,
				});

				// RefreshToken exp after 2 day
				// date.setDate(date.getDate() + 2)
				Cookies.set(REFRESH_TOKEN_KEY, refreshToken, {
					expires: 1,
				});

				return { accessToken, refreshToken };
			})
			.catch((error) => {
				localStorage.removeItem(storageConstant.USER);
				Cookies.remove(ACCESS_TOKEN_KEY);
				Cookies.remove(REFRESH_TOKEN_KEY);

				return error;
			});
	},
	checkEmail: (email) =>
		checkEmail({ email: email })
			.then(({ data }) => Promise.resolve(data.data))
			.catch((err) => Promise.reject(err)),
	forgotPassword: async (email) =>
		forgotPassword({ email: email })
			.then(({ data }) => Promise.resolve(data.data))
			.catch((err) => Promise.reject(err)),
	checkUser: (params) =>
		checkUser(params)
			.then(({ data }) => Promise.resolve(data.data))
			.catch((err) => Promise.reject(err)),
	resetPassword: (params, password) =>
		resetPassword(params, { newPassword: password })
			.then(() => Promise.resolve())
			.catch((err) => Promise.reject(err)),
};

export default userService;
