import 'tw-elements';

import { applyMiddleware, compose, createStore } from 'redux'; // createStore

import { BrowserRouter } from 'react-router-dom';
import { FocusProvider } from './context/Focus.context';
import { HighlightProvider } from './context/Highlight.context';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom/client';
import RouteApp from './routes/route';
import { ToastContainer } from 'react-toastify';
import reducers from './redux/reducers/_index.reducer';
import thunkMiddleware from 'redux-thunk';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	reducers,
	composeEnhancers(applyMiddleware(thunkMiddleware))
); //Tạo store
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	// <React.StrictMode>
	<Provider store={ store }>

		<FocusProvider>
			<HighlightProvider>
				<BrowserRouter>
					<ToastContainer
						position="top-right"
						autoClose={ 2000 }
						draggable
						pauseOnHover={ true }
						hideProgressBar={ true }
						newestOnTop={ false }
						pauseOnFocusLoss={ true }
						closeOnClick
						limit={ 3 }
					/>
					<RouteApp />
				</BrowserRouter>
			</HighlightProvider>
		</FocusProvider>

	</Provider>
	// </React.StrictMode>
);
