
import { createContext, useState } from 'react';

const HighlightContext = createContext();

const HighlightProvider = ({ children }) => {

	const [context, setContext] = useState('default context value');


	return (
		<HighlightContext.Provider value={[context, setContext]} >
			{children}
		</HighlightContext.Provider>
	);

};

export { HighlightContext, HighlightProvider };