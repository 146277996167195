import { useDispatch, useSelector } from 'react-redux';

import ModalLogin from '../../views/auth/ModalLogin';
import ModalLogout from '../../views/auth/ModalLogout';
import PAGE from '../../config/path.route';
import React from 'react';
import modalAction from '../../redux/modal/modal.action';
import modalSelector from '../../redux/modal/modal.selector';
import { useNavigate } from 'react-router-dom';
import ModalDelete from '../../views/user.events/components/ModalDelete';

const ModalManagement = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const modalLogin = useSelector(modalSelector.modalLogin);
	const modalSignUp = useSelector(modalSelector.modalSignUp);
	const modalLogout = useSelector(modalSelector.modalLogout);
	const modalDelete = useSelector(modalSelector.modalDelete);

	const closeTiming = 20;
	const backPage = -1;

	const closeModalLogin = () => {
		const pathname = window.location.pathname;
		if (pathname !== PAGE.dashboard) {
			setTimeout(() => {
				dispatch(modalAction.closeModalLogin());
			}, closeTiming);
			navigate(backPage);
			return;
		}
		dispatch(modalAction.closeModalLogin());
	};

	const closeModalSignUp = () => {
		const pathname = window.location.pathname;
		if (pathname !== PAGE.dashboard) {
			setTimeout(() => {
				dispatch(modalAction.closeModalSignUp());
			}, closeTiming);
			navigate(backPage);
			return;
		}
		dispatch(modalAction.closeModalSignUp());
	};
	const closeModalLogout = () => {
		dispatch(modalAction.closeModalLogout());
	};

	const closeModalDelete = () => {
		dispatch(modalAction.closeModalDelete());
	};

	return (
		<>
			{modalLogin.isOpen && (
				<ModalLogin
					isOpen={modalLogin.isOpen}
					funToggle={closeModalLogin}
					activeLoginTab={modalLogin.isOpen}
					defaultMode={'login'}
				/>
			)}
			{modalSignUp.isOpen && (
				<ModalLogin
					isOpen={modalSignUp.isOpen}
					funToggle={closeModalSignUp}
					activeLoginTab={false}
					defaultMode={'register'}
				/>
			)}
			{modalLogout.isOpen && (
				<ModalLogout
					isOpen={modalLogout.isOpen}
					funToggle={closeModalLogout}
				/>
			)}
			{modalDelete.isOpen && (
				<ModalDelete
					isOpen={modalDelete.isOpen}
					funToggle={closeModalDelete}
				/>
			)}
		</>
	);
};

export default ModalManagement;
