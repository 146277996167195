import { logIn, signUp, changePassword } from '../api/auth.api';
import { SUCCESS_KEY } from '../config/constant/common.constant';
import storageConstant from '../config/constant/storage.constant';

import Cookies from 'js-cookie';

const msUnit = 1000;
const hourToSecond = 36000;

const authService = {
	handleLoginIn: async (username, password) => {
		username = username.trim();
		password = password.trim();
		const { data } = await logIn(username, password);

		if (data.code === SUCCESS_KEY) {
			const { user, accessToken, refreshToken } = data.data;
			localStorage.setItem(
				storageConstant.USER,
				JSON.stringify(user)
			);

			let date = new Date();
			date.setTime(date.getTime() + hourToSecond * msUnit);

			Cookies.set(storageConstant.ACCESS_TOKEN, accessToken, {
				expires: date,
			});
			Cookies.set(
				storageConstant.REFRESH_TOKEN,
				refreshToken,
				{ expires: 1 }
			); // 1 day
			return user;
		} else {
			const error = new Error();
			error.statusCode = data.code;
			error.errorMessage = data.message;
			return Promise.reject(error); // 401: MK sai, 404: TK ko ton tai
		}
	},

	handleSignUp: async (username, password) => {
		username = username.trim();
		password = password.trim();
		const { data } = await signUp(username, password);

		if (data.code === SUCCESS_KEY) {
			const user = data.data;
			localStorage.setItem(
				storageConstant.USER,
				JSON.stringify(user)
			);
			return user;
		} else {
			const error = new Error();
			error.statusCode = data.code;
			error.errorMessage = data.message;
			return Promise.reject(error); // 400: Ten dang nhap da ton tai
		}
	},
	handleChangePassword: async (oldPassword, newPassword) =>
		changePassword({
			oldPassword: oldPassword,
			newPassword: newPassword,
		})
			.then(({ data }) => {
				const { accessToken, refreshToken } = data.data;

				let date = new Date();
				Cookies.set(
					storageConstant.ACCESS_TOKEN,
					accessToken,
					{ expires: date.getTime() }
				);
				Cookies.set(
					storageConstant.REFRESH_TOKEN,
					refreshToken,
					{ expires: 1 }
				);
				return Promise.resolve();
			})
			.catch((err) => Promise.reject(err)),
};
export default authService;
