import Avatar from '../Avatar';
import { Link, useLocation } from 'react-router-dom';
import NavbarRoute from '../../routes/Navbar.route';
import React from 'react';

const TippyMenu = ({
	loggedIn,
	user,
	visible,
	onClose,
	showLoginForm,
	showRegisterForm,
}) => {
	const { pathname } = useLocation();
	return (
		visible && (
			<div
				className="fixed inset-0 z-[999] md:hidden"
				role="dialog"
				aria-modal="true"
			>
				<div
					className="fixed inset-0 bg-black/20 backdrop-blur-sm "
					id="headlessui-dialog-overlay-23"
					aria-hidden="true"
				>
					<div className="fixed right-4 top-4 w-full max-w-xs rounded-lg bg-[#131314] p-6 text-base font-semibold text-slate-900 shadow-lg ">
						<button
							type="button"
							className="absolute right-5 top-5 flex h-8 w-8 items-center justify-center text-yellow-500 hover:text-slate-600 "
							tabIndex="0"
							onClick={onClose}
						>
							<span className="sr-only">Đóng</span>
							<svg
								viewBox="0 0 10 10"
								className="h-2.5 w-2.5 overflow-visible"
								aria-hidden="true"
							>
								<path
									d="M0 0L10 10M10 0L0 10"
									fill="none"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
								></path>
							</svg>
						</button>
						<ul className="space-y-6">
							{NavbarRoute.map((navbar, id) => {
								if (navbar.element) {
									const Element = navbar.element;
									const isActive = navbar.path === pathname;
									return (
										<li key={id}>
											<Element
												to={navbar.path}
												className={`text-gray-100 hover:text-yellow-400 ${
													isActive && 'text-yellow-400'
												}`}
											>
												{navbar.content}
											</Element>
										</li>
									);
								}
								return (
									<li key={id}>
										<a
											href={navbar.path}
											className="text-gray-100 hover:text-yellow-400 "
										>
											{navbar.content}
										</a>
									</li>
								);
							})}
						</ul>
						{loggedIn && (
							<div className="mt-6 border-t border-slate-200 pt-6 ">
								<div className="flex items-center justify-start">
									<Link
										to="/tai-khoan"
										className="flex flex-row justify-center"
									>
										<label
											htmlFor="account"
											className="font-normal text-white"
										>
											Đi tới Tài khoản
										</label>
										<div className="ml-6 block text-slate-400 hover:text-slate-500 ">
											<span className="sr-only">
												Avatar User
											</span>
											<Avatar
												username={user.user_name}
												avatarUrl={user.avatar}
											/>
										</div>
									</Link>
								</div>
							</div>
						)}
						{!loggedIn && (
							<>
								<div className="mt-6 border-t border-slate-200 pt-6 ">
									<div className="flex items-center justify-start">
										<Link
											className="text-gray-100 hover:text-yellow-400"
											to="#"
											onClick={showLoginForm}
										>
											Đăng nhập
										</Link>
									</div>
								</div>
								<div className="mt-6">
									<div className="flex items-center justify-start">
										<button
											className="flex h-8 w-full items-center justify-center rounded-lg bg-yellow-400 px-3 font-semibold text-black hover:bg-white focus:outline-none focus:ring-2"
											onClick={showRegisterForm}
										>
											Đăng ký
										</button>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		)
	);
};

export default TippyMenu;
