
import BLANK from './blank.jpg';
import CHU_RE_3 from './chu_re_3.png';
import CO_DAU_3 from './co_dau_3.png';
import FEATURE_1 from './bg_service_1.png';
import FEATURE_2 from './bg_service_2.png';
import HERO_ICON from './bg_2.png';
import MOBILE_VIEW from './bg_mobile_view.png';
import PLAN_FLY from './plan.webp';
import LOTUS from './lotus.png';

const IMAGES = {
	LOTUS,
	CO_DAU_3,
	CHU_RE_3,
	HERO_ICON,
	MOBILE_VIEW,
	FEATURE_1,
	FEATURE_2,
	BLANK,
	PLAN_FLY,
};

export default IMAGES;
