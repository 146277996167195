import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

const FormSignUp = ({
	formikSignUp,
	changeMode,
	setMode,
	checkValid,
	setCheckValid,
}) => {
	const focusInput = useRef(null);
	const [showPassword, setShowPassword] = useState(false);
	const toogleShowPassword = () => {
		setShowPassword(!showPassword);
	};
	useEffect(() => {
		focusInput.current.focus();
	}, []);
	return (
		<div className="mx-auto w-full md:mx-0 md:w-full lg:w-9/12">
			<div className="flex w-full flex-col rounded-xl bg-[#131314] p-5 shadow-xl md:p-10">
				<h2 className="text-left text-2xl font-bold text-yellow-400 sm:mb-5">
					Đăng ký
				</h2>
				<form
					onSubmit={formikSignUp.handleSubmit}
					className="w-full"
				>
					<div
						id="input"
						className="my-5 flex w-full flex-col"
					>
						<label
							htmlFor="username"
							className="mb-2 text-left text-gray-300 "
						>
							Tên đăng nhập
						</label>
						<input
							ref={focusInput}
							type="text"
							id="username"
							name="username"
							placeholder="Nhập tên đăng nhập"
							className={`appearance-none rounded-lg border px-4 py-3 text-black placeholder:text-gray-400 focus:shadow-lg focus:outline-none focus:ring-1 ${
								checkValid &&
								formikSignUp
									.errors
									.username
									? 'border-red-500 focus:border-red-500 focus:ring-red-600'
									: 'border-gray-100 focus:border-yellow-500 focus:ring-yellow-500'
							}`}
							value={
								formikSignUp
									.values
									.username
							}
							// onChange={formikSignUp.handleChange}
							onChange={(event) =>
								formikSignUp.setFieldValue(
									'username',
									event.target.value.trim()
								)
							}
							// helperText={formikSignUp.errors.username}
						/>
						<span className="mt-1 text-left text-sm text-red-500">
							{checkValid &&
								formikSignUp
									.errors
									.username}
						</span>
					</div>
					<div className="my-5 flex w-full flex-col">
						<label
							htmlFor="password"
							className="mb-2 text-left text-gray-300"
						>
							Mật khẩu
						</label>
						<div className="flex">
							<input
								type={
									showPassword
										? 'text'
										: 'password'
								}
								id="password"
								name="password"
								placeholder="Nhập mật khẩu"
								className={`w-full appearance-none rounded-lg border-2 px-4 py-3 text-black placeholder:text-gray-400 focus:shadow-lg focus:outline-none focus:ring-1 ${
									checkValid &&
									formikSignUp
										.errors
										.password
										? 'border-red-500 focus:border-red-500 focus:ring-red-600'
										: 'border-gray-100 focus:border-yellow-500 focus:ring-yellow-500'
								}`}
								value={
									formikSignUp
										.values
										.password
								}
								// onChange={formikSignUp.handleChange}
								onChange={(
									event
								) =>
									formikSignUp.setFieldValue(
										'password',
										event.target.value.trim()
									)
								}
							/>
							<button
								className={
									'text-black -ml-9 text-lg cursor-pointer fas fa-eye' +
									(showPassword
										? ''
										: '-slash')
								}
								onClick={
									toogleShowPassword
								}
							></button>
						</div>
						<span className="mt-1 text-left text-sm text-red-500">
							{checkValid &&
								formikSignUp
									.errors
									.password}
						</span>
					</div>
					<div className="my-5 flex w-full flex-col">
						<label
							htmlFor="confirmPassword"
							className="mb-2 text-left text-gray-300"
						>
							Xác nhận lại mật khẩu
						</label>
						<div className="flex">
							<input
								type={
									showPassword
										? 'text'
										: 'password'
								}
								id="confirmPassword"
								name="confirmPassword"
								placeholder="Xác nhận lại mật khẩu"
								className={`w-full appearance-none rounded-lg border px-4 py-3 text-black placeholder:text-gray-400 focus:shadow-lg focus:outline-none focus:ring-1 ${
									checkValid &&
									formikSignUp
										.errors
										.confirmPassword
										? 'border-red-500 focus:border-red-500 focus:ring-red-600'
										: 'border-gray-100 focus:border-yellow-500 focus:ring-yellow-500'
								}`}
								value={
									formikSignUp
										.values
										.confirmPassword
								}
								// onChange={formikSignUp.handleChange}
								onChange={(
									event
								) =>
									formikSignUp.setFieldValue(
										'confirmPassword',
										event.target.value.trim()
									)
								}
							/>
							<button
								className={
									'text-black -ml-9 text-lg cursor-pointer fas fa-eye' +
									(showPassword
										? ''
										: '-slash')
								}
								onClick={
									toogleShowPassword
								}
							></button>
						</div>
						<span className="mt-1 text-left text-sm text-red-500">
							{checkValid &&
								formikSignUp
									.errors
									.confirmPassword}
						</span>
					</div>
					<div className="mb-1 mt-5 flex w-full flex-col sm:mb-5">
						<button
							type="submit"
							onClick={() =>
								setCheckValid(
									true
								)
							}
							className="w-full rounded-lg bg-gradient-to-b from-yellow-400 to-yellow-300 py-4 text-black hover:from-yellow-300 hover:to-yellow-400  hover:shadow-lg "
						>
							<div className="flex flex-row items-center justify-center">
								<div className="font-bold capitalize text-black">
									Đăng ký
								</div>
							</div>
						</button>
						<div className="mt-1 flex justify-evenly sm:mt-5">
							<div>
								<span className="text-gray-300">
									Bạn đã
									có tài
									khoản.{' '}
								</span>
								<Link
									to="#"
									className="w-full text-right font-medium text-yellow-500 underline"
									onClick={() => {
										setMode(
											'login'
										);
										changeMode();
									}}
								>
									Đăng
									nhập!
								</Link>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default FormSignUp;
