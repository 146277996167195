import Cookies from 'js-cookie';
import axios from 'axios';
import envConstants from '../config/key';
import storageConstant from '../config/constant/storage.constant';
import { toast } from 'react-toastify';
import userService from '../views/user/User.service';
import ToastNotify from '../utils/Toast';
import { UNAUTHORIZED_KEY } from '../config/constant/common.constant';

toast.configure();

const instance = axios.create({
	baseURL: envConstants.API_URL,
	// withCredentials: true
});

instance.interceptors.request.use(
	async (config) => {
		const token = Cookies.get(storageConstant.ACCESS_TOKEN);
		config.headers.Authorization = token ? token : '';
		config.headers.PathName = window.location.pathname;
		config.headers.Search = window.location.search;
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response) => {
		// Thrown error for request with OK status code
		const { data } = response;
		const originalRequest = response.config;

		const isExpAccessToken =
			data.code === UNAUTHORIZED_KEY &&
			data.data === 'isUser';
		if (isExpAccessToken) {
			// Is auto call get new access and refresh token
			if (data.isAuto) {
				originalRequest._retry = true;
				return userService
					.getNewAccessAndRefreshToken()
					.then(({ accessToken }) => {
						// Set Authorization Header new value
						axios.defaults.headers.common[
							'Authorization'
						] = accessToken;
						return instance(
							originalRequest
						);
					})
					.catch((error) => {
						console.log(
							localStorage.getItem(
								'user'
							)
						);
						if (
							localStorage.getItem(
								'user'
							) !== null
						) {
							localStorage.clear();
							ToastNotify.Error(
								'Phiên đăng nhập đã hết hạn vui lòng đăng nhập lại!'
							);
						}

						Cookies.remove(
							storageConstant.ACCESS_TOKEN
						);
						Cookies.remove(
							storageConstant.REFRESH_TOKEN
						);

						return Promise.reject(error);
					});
			}
		}

		return response;
	},
	(error) => {
		if (error.code === 'ECONNABORTED') {
			return Promise.reject(error);
		}
		const { response } = error;
		const { data } = response;
		if (data?.message) {
			const error = new Error(data.message);
			error.status = response.status;
			return Promise.reject(error);
		}
		return Promise.reject(error);
	}
);

export default instance;
