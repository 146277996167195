import axios from './axios';

export const logIn = (username, password) =>
	axios.post('/user/login', { username: username, password });

export const signUp = (username, password) =>
	axios.post('/user/signup', { username: username, password });

export const changePassword = (payload = {}) =>
	axios.post('/user/change-password', payload);
