/* eslint-disable no-undef */
const envConstants = {
	API_URL:
		process.env.REACT_APP_API_URL || 'http://localhost:8888/api/v1',
	SERVER_URL: process.env.REACT_APP_SERVER_URL || 'http://localhost:8888',
	CLIENT_URL:
		process.env.REACT_APP_CLIENT_URL || 'http://localhost:3000/',
	BLOG_URL: process.env.BLOG_URL || 'https://blog.chungvui.akb.vn',
};
export default envConstants;
