import { useRef, useEffect } from 'react';

const FormForgotPassword = ({ formikForgotPassword, setMode, changeMode }) => {
	const focusInput = useRef(null);

	useEffect(() => {
		focusInput.current.focus();
	}, []);

	return (
		<div className="mx-auto w-full md:mx-0 md:w-full lg:w-9/12">
			<div className="flex w-full flex-col rounded-xl bg-white p-5 shadow-xl md:p-10">
				<h2 className="mb-5 text-left text-2xl font-bold text-gray-800">
					Quên mật khẩu
				</h2>

				<div
					id="input"
					className="my-5 flex w-full flex-col"
				>
					<label
						htmlFor="username"
						className="mb-2 text-left text-gray-500"
					>
						Nhập email để tìm kiếm tài khoản
						của bạn
					</label>
					<input
						ref={focusInput}
						type="text"
						id="confirm_email"
						name="confirm_email"
						placeholder="Nhập email"
						className={`border-1 appearance-none rounded-lg px-4 py-3 text-black placeholder:text-gray-300 focus:shadow-lg focus:outline-none focus:ring-1 ${
							formikForgotPassword
								.errors.email
								? 'border-red-500 focus:border-red-500 focus:ring-red-600'
								: 'border-gray-100 focus:border-main-300 focus:ring-main-300'
						}`}
						value={
							formikForgotPassword
								.values.email
						}
						onChange={(event) =>
							formikForgotPassword.setFieldValue(
								'email',
								event.target.value.trim()
							)
						}
					/>
					<span className="mt-1 text-left text-sm text-red-500">
						{
							formikForgotPassword
								.errors.email
						}
					</span>
				</div>

				<div className="my-5 flex w-full flex-col">
					<button
						className="bg-gradient-to-r w-full rounded-lg from-[#51ADF5] to-[#2196F3] py-4 text-white hover:from-[#2196F3] hover:to-[#51ADF5]  hover:shadow-lg "
						onClick={
							formikForgotPassword.handleSubmit
						}
					>
						<div className="flex flex-row items-center justify-center">
							<div className="font-bold capitalize text-white">
								Tìm kiếm
							</div>
						</div>
					</button>

					<button
						type="button"
						className="mt-3 w-full rounded-lg bg-gray-300 py-4 text-white hover:shadow-lg "
						onClick={() => {
							setMode('login');
							changeMode();
						}}
					>
						<div className="flex flex-row items-center justify-center">
							<div className="font-bold capitalize text-gray-700">
								Trở lại
							</div>
						</div>
					</button>
				</div>
			</div>
		</div>
	);
};

export default FormForgotPassword;
