const showUserDisplayName = (username) => {
	const showFirstChar = 0;
	const showSecondChar = 2;
	if (username) {
		return username
			.split('')
			.slice(showFirstChar, showSecondChar)
			.join('')
			.toUpperCase();
	}
	return 'User';
};
const FormConfirm = ({ user, setMode, changeMode, handleSendEmailVerify }) => (
	<div className="mx-auto w-full md:mx-0 md:w-full lg:w-9/12">
		<div className="flex w-full flex-col rounded-xl bg-white p-5 shadow-xl md:p-10">
			<h2 className="mb-5 text-center text-2xl font-bold text-gray-800">
				Xác nhận tài khoản
			</h2>

			<div id="input" className="my-5 flex w-full flex-col">
				<div
					className={
						'relative mx-auto inline-flex h-20 w-20 items-center justify-center  overflow-hidden rounded-full bg-[#7265e6] text-center'
					}
				>
					<span className="text-3xl font-medium text-white ">
						{showUserDisplayName(
							user.user_name
						)}
					</span>
				</div>
				<p className="mx-auto mt-3 text-2xl font-bold text-black">
					{user.user_name}
				</p>
			</div>

			<div className="my-5 flex w-full flex-col">
				<button
					className="bg-gradient-to-r w-full rounded-lg from-[#51ADF5] to-[#2196F3] py-4 text-white hover:from-[#2196F3] hover:to-[#51ADF5]  hover:shadow-lg "
					onClick={() => {
						handleSendEmailVerify(
							user.email
						);
					}}
				>
					<div className="flex flex-row items-center justify-center">
						<div className="font-bold capitalize text-white">
							Gửi email xác nhận đổi
							mật khẩu
						</div>
					</div>
				</button>
				<button
					className="mt-5 w-full rounded-lg bg-gray-300 py-4 text-white hover:shadow-lg "
					// onClick={formikForgotPassword.handleSubmit}
					onClick={() => {
						setMode('forgot-password');
						changeMode();
					}}
				>
					<div className="flex flex-row items-center justify-center">
						<div className="font-bold capitalize text-gray-700">
							Đây không phải tài khoản
							của tôi
						</div>
					</div>
				</button>
			</div>
		</div>
	</div>
);

export default FormConfirm;
